<template>
    <div class="overflow-x-scroll scrollbar">
        <div class="flex flex-nowrap">
            <div
                v-for="product in products"
                class="flex-none mb-3"
            >
                <div class="w-40 mr-5 shadow-sm">
                    <Link :href="route('products.show', [product])">
                        <div
                            class="flex justify-center h-40 items-center border border-b-0 border-black">
                            <ResponsiveImage
                                class="h-36 w-36 object-cover object-center"
                                :alt="product.title"
                                :media-item="product.image"
                            />
                        </div>

                        <div class="p-3 bg-black border border-l-black border-t-0">
                            <p class="h-10">
                                <span class="line-clamp-2 text-white text-xs mb-3 max-h-10">{{ product.title }}</span>
                            </p>
                            <p class="text-green-500 mt-1 text-xs font-heading h-4">
                                {{ product.price?.formatted }}
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Link} from "@inertiajs/vue3";
import ResponsiveImage from "@/Components/ResponsiveImage.vue";

export default {
    components: {ResponsiveImage, Link},
    props: {
        products: Array,
    }
}
</script>
